<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div v-if="!hasToken" class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Sie sind nicht angemeldet. Bitte melden Sie sich <router-link to="/login">hier</router-link> an.</p>
          </div>
        </div>
        <div v-else>
          <div class="container bg-light">
            <h3 class="text-left pt-3">Zugriffsobergrenze erreicht</h3>
            <div class="row justify-content-around pt-3 pb-3">
              <div class="col-sm-12 text-left">
                <div v-if="!this.loadingSubsiteContent" v-html="this.textSichtbarkeitsbegrenzung"></div>
              </div>
            </div>
            <p class="text-left w-100" v-if="warning">
              <b-alert show variant="warning">
                {{ warning }}
              </b-alert>
            </p>
            <div class="row justify-content-around pt-1 pb-5">
              <div class="col-sm-12 text-center">
                <router-link class="btn btn-default" :to="{name: 'fondslist', params: {client}}">OK</router-link>
              </div>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";

export default {
  name: "ConfidentialityAgreement",
  components: {Hero,HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },

      ],
    }
  },
  data: () => ({
    warning: null,
    error: null,
    submitSuccess: false,
    subsiteContent: [],
    loadingSubsiteContent: false,
    textSichtbarkeitsbegrenzung: '',
  }),
  mounted() {
    this.loadSubsiteContent();
  },
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },

    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected/32360';
      axios.get(uri, config)
        .then(response => {
          this.subsiteContent = response.data;
          this.loadingSubsiteContent = false;
          this.textSichtbarkeitsbegrenzung = this.getHtmlContent( response.data );
        } )
        .catch(error => {
          console.log( error );
          this.loadingSubsiteContent = true;
        })
    },
    getHtmlContent( content ) {
      return content.htmlcontent;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
}
</script>

<style scoped>

</style>